@font-face {
  font-family: Lato;
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/Lato-Thin.ttf') format('truetype');
}

@font-face {
  font-family: Lato;
  font-weight: 100;
  font-style: italic;
  font-display: swap;
  src: url('/fonts/Lato-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: Lato;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/Lato-Light.ttf') format('truetype');
}

@font-face {
  font-family: Lato;
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url('/fonts/Lato-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: Lato;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Lato;
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url('/fonts/Lato-Italic.ttf') format('truetype');
}

@font-face {
  font-family: Lato;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Lato;
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url('/fonts/Lato-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: Lato;
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/Lato-Black.ttf') format('truetype');
}

@font-face {
  font-family: Lato;
  font-weight: 900;
  font-style: italic;
  font-display: swap;
  src: url('/fonts/Lato-BlackItalic.ttf') format('truetype');
}

body {
  font-family: Lato, sans-serif;
}
